import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Card, { CardContent, CardImage, CardTitle } from '../elements/card';

const Pour_mediter = () => (
  <Layout>
    <div className="columns">
        <div className="column">
            <Link to='/contes'>
                <Card>
                    <CardContent>
                        <CardTitle title="Contes"/>
                    </CardContent>
                    <CardImage src="contes" alt="Contes" hoverable="true" size="is-4by3" />
                </Card>
            </Link>
        </div>
        <div className="column">
            <Link to='/reflexions'>
                <Card>
                    <CardContent>
                        <CardTitle title="Réflexions"/>
                    </CardContent>
                    <CardImage src="reflexions" alt="Réflexions" hoverable="true" size="is-4by3" />
                </Card>
            </Link>
        </div>
    </div>
  </Layout>
)

export default Pour_mediter;